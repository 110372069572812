<template>
	<div class="jrlEvaluate" id="jrlEvaluate">
		<!-- 标题部分 -->
		<div class="title-hang">
			<div class="fl">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl"></div>
			<div class="zhuangshizj fl">{{ projectData.project }}_切换画面</div>
			<div class="zhuangshiyb fl">
				<div class="fl">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 0vh;margin-left: 16vw" />
				</div>
			</div>
		</div>

		<!-- 下半部分 -->
		<div class="mainall">
			<div class="qxbutton" :style="{background:(infoList.G_SOUREC.BCSALL_B ?'#29DFAE':'red')}" @click="
                  toIpt(
                    infoList.G_SOUREC.BCSALL_B,
                    
                    ' 一键全切',
                      'BCSALL_B',
                      'G_SOUREC',
                  
                  )
                ">一键全切</div>
			<!-- 左边 -->
			<div class="fl leftmain">
				<div class="leftmain-row1">
					切换状态
				</div>
				<div class="lablf">空气阀门</div>
				<div class="line"></div>
				<div class="col1">
					<div class="row1 fl">
						<div>
							<div class="fl hlabe">均热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS101_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">二加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS101_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">三加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS105_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">一加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS120_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">鼓风压力</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS116_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">煤气压力</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS124_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="lablf">煤气阀门</div>
				<div class="line"></div>
				<div class="col1">
					<div class="row1 fl">
						<div>
							<div class="fl hlabe">均热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS102_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">二加热段</div>
							<div class="fl flexbtn "
								:style="{background:(infoList.G_SOUREC.BCS110_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">三加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS106_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">一加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS121_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">鼓风压力</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS117_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>


				</div>
				<div class="lablf">空烟阀门</div>
				<div class="line"></div>
				<div class="col1">
					<div class="row1 fl">
						<div>
							<div class="fl hlabe">均热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS103_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">二加热段</div>
							<div class="fl flexbtn "
								:style="{background:(infoList.G_SOUREC.BCS111_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">三加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS107_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">一加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS122_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">炉膛压力</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS113_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>


				</div>
				<div class="lablf">煤烟阀门</div>
				<div class="line"></div>
				<div class="col1">
					<div class="row1 fl">
						<div>
							<div class="fl hlabe">均热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS104_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">二加热段</div>
							<div class="fl flexbtn "
								:style="{background:(infoList.G_SOUREC.BCS112_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">三加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS108_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">一加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS123_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">炉膛压力</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_SOUREC.BCS114_B ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>


				</div>
				<div class="lablf">上限减煤气</div>
				<div class="line"></div>
				<div class="col1">
					<div class="row1 fl">
						<div>
							<div class="fl hlabe">均热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_PFJ.SXSEL1 ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">二加热段</div>
							<div class="fl flexbtn " :style="{background:(infoList.G_PFJ.SXSEL3 ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>
				<div class="col1">
					<div class="row1 fl ">
						<div>
							<div class="fl hlabe">三加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_PFJ.SXSEL2 ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>
					<div class="row1 fl jg11">
						<div>
							<div class="fl hlabe">一加热段</div>
							<div class="fl flexbtn" :style="{background:(infoList.G_PFJ.SXSEL4 ?'red':'#29DFAE')}">
							</div>
						</div>
					</div>

				</div>



			</div>
			<!-- 中间 -->
			<div class="fl centermain">
				<div class="cen-top">
					<div class="leftmain-row1 jjj">
						控制点设定
					</div>
					<div class="cenrow1">
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.T11SP,
                    
                    ' 均热段',
                      'T11SP',
                      'G_TEMP',
                  
                  )
                ">
								{{ infoList.G_TEMP.T11SP }}
							</div>
							<div class="cencollb2">均热段</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.T21SP,
                    ' 三加热段',
                    'T21SP',
                     'G_TEMP'
                  )
                ">
								{{ infoList.G_TEMP.T21SP }}
							</div>
							<div class="cencollb2">三加热段</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.T31SP,
                    ' 二加热段',
                     'T31SP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.T31SP }}
							</div>
							<div class="cencollb2">二加热段</div>
						</div>

					</div>
					<div class="cenrow1">
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.T41SP,
                    ' 一加热段',
                     'T41SP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.T41SP }}
							</div>
							<div class="cencollb2">一加热段</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_PFJ.FYSP,
                    ' 炉膛压力',
                     'FYSP',
                    'G_PFJ'
                   
                  )
                ">
								{{ infoList.G_PFJ.FYSP }}
							</div>
							<div class="cencollb2">炉膛压力</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_PFJ.PKQ_SP1,
                    ' 鼓风压力',
                    'PKQ_SP1',
                    'G_PFJ',
                    
                  )
                ">
								{{ infoList.G_PFJ.PKQ_SP1 }}
							</div>
							<div class="cencollb2">鼓风压力</div>
						</div>

					</div>
					<div class="cenrow1">
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_PFJ.PMQ_SP,
                    ' 煤气压力',
                     'PMQ_SP',
                    'G_PFJ'
                   
                  )
                ">
								{{ infoList.G_PFJ.PMQ_SP }}
							</div>
							<div class="cencollb2">煤气压力</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.JRDKPTSP,
                    ' 均热空烟',
                    'JRDKPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.JRDKPTSP }}
							</div>
							<div class="cencollb2">均热空烟</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.SJRKPTSP,
                    ' 三加空烟',
                    'SJRKPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.SJRKPTSP }}
							</div>
							<div class="cencollb2">三加空烟</div>
						</div>

					</div>
					<div class="cenrow1">
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.EJRKPTSP,
                    ' 二加空烟',
                    'EJRKPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.EJRKPTSP }}
							</div>
							<div class="cencollb2">二加空烟</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.YJRKPTSP,
                    ' 一加空烟',
                    'YJRKPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.YJRKPTSP }}
							</div>
							<div class="cencollb2">一加空烟</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.JRDMPTSP,
                    ' 均热煤烟',
                     'JRDMPTSP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.JRDMPTSP }}
							</div>
							<div class="cencollb2">均热煤烟</div>
						</div>

					</div>
					<div class="cenrow1">
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.SJRMPTSP,
                    ' 三加煤烟',
                     'SJRMPTSP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.SJRMPTSP }}
							</div>
							<div class="cencollb2">三加煤烟</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.EJRMPTSP,
                    ' 二加煤烟',
                    'EJRMPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.EJRMPTSP }}
							</div>
							<div class="cencollb2">二加煤烟</div>
						</div>
						<div class="cencol fl">
							<div class="cencollb1" @click="
                  toIpt(
                    infoList.G_TEMP.YJRMPTSP,
                    ' 一加煤烟',
                    'YJRMPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.YJRMPTSP }}
							</div>
							<div class="cencollb2">一加煤烟</div>
						</div>

					</div>
				</div>
				<div class="cen-bottom">
					<div class="fl bot1"></div>
					<div class="fl bot2">
						<div class="bot2lb" @click="toCompon(2, 'KHMQGGB', 'G_KHTJ', 'KHMQGGB_J1','本班吨钢耗煤气')"
							@dblclick="Cclick(infoList.G_KHTJ.KHMQGGB,'KHMQGGB', 'G_KHTJ', 'KHMQGGB_J1')">
							{{infoList.G_KHTJ.KHMQGGB}}</div>
						<div class="bot2lb1">本班吨钢耗气</div>
					</div>

				</div>
			</div>
			<!-- 右边 -->
			<div class="fl rightmain">
				<div class="rigttop">
					<div class="leftmain-row1">
						数据对比
					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 类别</div>
						<div class="fl rigwidth1"> 空燃比</div>
						<div class="fl rigwidth1"> 煤气量上限</div>
					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 均热段</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_BCS.JBFMB_B11,
                    ' 均热段空燃比',
                      'JBFMB_B11',
                    'G_BCS',
                  
                  )
                ">
							{{ infoList.G_BCS.JBFMB_B11 }}
						</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_TEMP.MQ11SPH,
                    ' 均热段煤气量上限',
                      'MQ11SPH',
                    'G_TEMP',
                  
                  )
                ">
							{{ infoList.G_TEMP.MQ11SPH }}
						</div>
					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 三加热段</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_BCS.JBFMB_B21,
                    ' 三加热段空燃比',
                     'JBFMB_B21',
                    'G_BCS',
                   
                  )
                ">
							{{ infoList.G_BCS.JBFMB_B21 }}
						</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_TEMP.MQ21SPH,
                    ' 三加热段煤气量上限',
                     'MQ21SPH',
                    'G_TEMP',
                   
                  )
                ">
							{{ infoList.G_TEMP.MQ21SPH }}
						</div>
					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 二加热段</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_BCS.JBFMB_B31,
                    ' 二加热段空燃比',
                    'JBFMB_B31',
                    'G_BCS'
                    
                  )
                ">
							{{ infoList.G_BCS.JBFMB_B31 }}
						</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_TEMP.MQ31SPH,
                    ' 二加热段煤气量上限',
                     'MQ31SPH',
                    'G_TEMP'
                   
                  )
                ">
							{{ infoList.G_TEMP.MQ31SPH }}
						</div>

					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 一加热段</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_BCS.JBFMB_B41,
                    ' 一加热段空燃比',
                     'JBFMB_B41',
                    'G_BCS'
                   
                  )
                ">
							{{ infoList.G_BCS.JBFMB_B41 }}
						</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_TEMP.MQ41SPH,
                    ' 一加热段煤气量上限',
                     'MQ41SPH',
                    'G_TEMP'
                   
                  )
                ">
							{{ infoList.G_TEMP.MQ41SPH }}
						</div>

					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 煤气压力</div>
						<div class="fl rigwidth1 lcolor">/
						</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_PFJ.MQSPH,
                    ' 煤气压力煤气量上限',
                     'MQSPH',
                    'G_PFJ'
                   
                  )
                ">
							{{ infoList.G_PFJ.MQSPH }}
						</div>
					</div>

					<div class="ringhtrow" style="margin-top:6vh">
						<div class="fl rigwidth1"> 类别</div>
						<div class="fl rigwidth1 "> 开度上限</div>
						<div class="fl rigwidth1"> 开度下限</div>
					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 鼓风机</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN16.OutT,
                    '鼓风机上限',
                     'OutT',
                    'G_MCSMAN__p__MAN16',
                   
                  )
                ">{{infoList.G_MCSMAN__p__MAN16.OutT}}</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN16.OutB,
                    '鼓风机下限',
                     'OutB',
                    'G_MCSMAN__p__MAN16',
                   
                  )
                ">{{infoList.G_MCSMAN__p__MAN16.OutB}}</div>
					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 空烟风机</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN13.OutT,
                    '空烟风机上限',
                     'OutT',
                    'G_MCSMAN__p__MAN13',
                   
                  )
                ">{{infoList.G_MCSMAN__p__MAN13.OutT}}</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN13.OutB,
                    '空烟风机下限',
                     'OutB',
                    'G_MCSMAN__p__MAN13',
                   
                  )
                ">{{infoList.G_MCSMAN__p__MAN13.OutB}}</div>
					</div>
					<div class="ringhtrow">
						<div class="fl rigwidth1 "> 煤烟风机</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN14.OutT,
                    '煤烟风机上限',
                     'OutT',
                    'G_MCSMAN__p__MAN14',
                   
                  )
                ">{{infoList.G_MCSMAN__p__MAN14.OutT}}</div>
						<div class="fl rigwidth1 lcolor" @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN14.OutB,
                    '煤烟风机下限',
                     'OutB',
                    'G_MCSMAN__p__MAN14',
                   
                  )
                ">{{infoList.G_MCSMAN__p__MAN14.OutB}}</div>
					</div>



				</div>
				<div class="rigtbotm">

					<div class="row2-1 fl">
						<div class="yuan1">
							<div class="text">BCS</div>
							<div class="number" @click="toCompon(2, 'HEART_1AR', 'G_SOUREC', 'HEART_1AR_J1','心跳数据返回')"
								@dblclick="Cclick(infoList.G_SOUREC.HEART_1AR,'HEART_1AR', 'G_SOUREC', 'HEART_1AR_J1')">
								{{infoList.G_SOUREC.HEART_1AR}}</div>
						</div>
					</div>
					<div class="row2-1 fl">
						<div class="yuan1">
							<div class="text">DCS</div>
							<div class="number" @click="toCompon(2, 'HEART_1A', 'G_SOUREC', 'HEART_1A_J1','1#心跳数据')"
								@dblclick="Cclick(infoList.G_SOUREC.HEART_1A,'HEART_1A', 'G_SOUREC', 'HEART_1A_J1')">
								{{infoList.G_SOUREC.HEART_1A}}</div>
						</div>
					</div>
					<div class="row2-3 fl " style="margin-left:2vw;margin-top:1vw">
						<div class="top-flex3-two-stuats" :style="[{color:(1?'red':'#22ca85')},
               {background:(1?'rgba(255,0,0,.2)':'rgba(51, 255, 204, 0.2)')},
               {border:(1?'1px solid #29DFAE':'1px solid #22ca85')}
               ]">
							通讯故障
							<!-- {{infoList.V_ALARM.P03ALM ? '通讯故障' :'通讯正常'}} -->
						</div>
					</div>
				</div>

			</div>
		</div>

		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>
		<JRLcysz v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus='isClose' :infoList='infoList'>
		</JRLcysz>
	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
	export default {
		name: "index",
		components: {
			Historical,
			inputVal,
			JRLcysz
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		data: () => {
			return {
				chName: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				spotArr: [],
				authInfo: [],
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
			};
		},

		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.$bus.$on("sendMsg", (msg, msg1) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},

		computed: {},
		mounted() {},
		methods: {
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无操作权限');
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type);
				}

			},
			closeCompon() {
				this.$emit('sendStatus', 'Param01', false)
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isClose() {
				this.flag = 3, this.$bus.$emit("footersendMsg", 3)
			},
			isClose1() {
				this.isHshow = false
			},
			toCompon(key, name, name2, name3, name4, titname) {

				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
					}
				}, 300);
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},

	};
</script>
<style lang="scss" scoped>
	.jrlEvaluate {
		width: 96vw;
		height: 100%;
		margin-left: 2vw;
		//  background: #e9f7ff;
		background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
			linear-gradient(#0e3449, #0e3449);
		background-blend-mode: normal, normal;
		overflow: hidden;
		//  cursor:move;
		border-radius: 5px;

		.ziti {
			font-size: 0.9vw;
			;
		}

		.zhuangshi1 {
			width: 30vw;
			;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 96vw;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter {
			height: 29vh;
			width: 100%;
			margin-top: 3vh;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter2 {
			margin-left: 1vw;
			width: 29vw;
			height: 29vh;
			// line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate03.png");
			background-size: 100% 100%;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.divcei {
			margin-top: 3vh;
			margin-left: 4vw;
			width: 9vw;
			height: 20vh;
			line-height: 20vh;
			background-image: url("~@/assets/images/rfl_evaluate02.png");
			background-size: 100% 100%;
			font-size: 1vw;
			;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #ffffff;
			text-align: center;
		}

		.divjjx {
			margin-top: 3vh;
			margin-left: 4vw;
			width: 9vw;
			height: 26vh;
			line-height: 26vh;
			font-size: 1vw;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #ffffff;
			text-align: center;
		}

		.zhuangshizb {
			width: 14vw;
			height: 3vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-left: 3.5vw;
			margin-top: 1.6vh;
		}

		.zhuangshizj {
			width: 26vw;
			height: 5vh;
			line-height: 5vh;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			font-family: MicrosoftYaHei;
			font-size: 1.5vw;
			;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.zhuangshiyb {
			width: 14vw;
			height: 3vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			margin-top: 1.6vh;
		}

		.title-hang {
			width: 100%;
		}

		// .mainall{
		//   margin-top:0vh;
		// }
		.leftmain {

			width: 28vw;
			height: 74vh;
			//  border: 1px salmon solid;
			background-image: url("~@/assets/images/jrl-evaluate.png");
			background-size: 100% 100%;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;
			margin-top: 1vh;

		}

		.centermain {
			font-size: 1vw;
		}

		.leftmain-row1 {
			margin-left: 2vw;
			margin-top: 1vh;
			color: #ffffff;
		}

		.qxbutton {
			width: 7vw;
			height: 4vh;
			// background-color: #00ff8a20;
			// border: solid 1px #00fc98;
			// color: #0de0ac;
			color: #fff;
			text-align: center;
			line-height: 2vh;
			cursor: pointer;
		}

		.lablf {
			width: 5vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vw;
			letter-spacing: 0vw;
			color: #6acafb;
			margin-left: 2vw;
		}

		.line {
			width: 25vw;
			border-bottom: solid 1px #09344a;
			margin-top: 3vh;
			margin-left: 2vw;
		}

		.col1 {
			width: 25vw;
			margin-top: 1vh;
			height: 2vh;
			margin-left: 2vw;
			// border: solid 1px #09344a;
		}

		.row1 {
			width: 12vw;
			height: 2vh;
			color: #6acafb;
			// border:1px saddlebrown solid;
			line-height: 2vh;
		}

		.hlabe {
			width: 5vw;
			font-size: 1vw;
		}

		.jg11 {
			margin-left: 1vw;
		}

		.flexbtn {
			width: 4vw;
			height: 2vh;
			// margin-left:1vw;
		}

		.rowjg {
			margin-top: 2vh;
		}

		.cen-top {
			width: 37vw;
			;
			height: 52vh;
			background-image: url("~@/assets/images/jrl-evaluate2.png");
			background-size: 100% 100%;
			margin-top: 1vh;
			margin-left: 1vw;

		}

		.cen-bottom {
			width: 37vw;
			;
			height: 21vh;
			background-image: url("~@/assets/images/jrl-evaluate5.png");
			background-size: 100% 100%;
			margin-top: 1vh;
			margin-left: 1vw;

		}

		.cenrow1 {
			margin-top: 1vh;
			font-size: 1vw;
		}

		.bot1 {
			width: 8vw;
			height: 12vh;
			background-image: url("~@/assets/images/jrl-switch.png");
			background-size: 100% 100%;
			margin-left: 8vw;
			margin-top: 6vh;
		}

		.bot2 {
			margin-top: 6vh;
			margin-left: 5vw;
		}

		.bot2lb {
			width: 10vw;
			height: 3vh;
			line-height: 3vh;
			font-size: 1.5vw;
			color: #00ffb4;
			cursor: pointer;
		}

		.bot2lb1 {
			width: 10vw;
			height: 3vh;
			line-height: 3vh;
			font-size: 1.1vw;
			;
			margin-top: 1vh;
			color: #8aeaff;


		}

		.cencol {
			width: 9vw;
			;
			height: 7vh;
			background-image: url("~@/assets/images/page_img2.png");
			background-size: 100% 100%;
			margin-left: 2vw;
			margin-top: 2vh;
			padding-left: 1vw;
		}

		.cencollb1 {
			color: #fff;
			width: 4vw;
			height: 2vh;
			cursor: pointer;
		}

		.cencollb2 {
			color: #6acafb;
			width: 6vw;
			height: 2vh;
			margin-top: 1vh;
		}

		.rigttop {
			width: 28vw;
			height: 52vh;
			background-image: url("~@/assets/images/jrl-evaluate2.png");
			background-size: 100% 100%;

		}

		.row2-3 {
			margin-left: auto;

			.top-flex3-two-stuats {
				width: 8vw;
				height: 4vh;
				text-align: center;
				line-height: 4vh;
			}
		}

		.rigtbotm {
			padding-top: 7vh;
			padding-left: 2vw;
			width: 26vw;
			height: 14vh;
			margin-top: 1vh;
			background-image: url("~@/assets/images/jrl-evaluate5.png");
			background-size: 100% 100%;
		}

		.row2-1 {
			margin-left: 1vw;

			width: 9vh;
			height: 9vh;
			border-radius: 9vh;
			border: .25vw solid rgba(54, 90, 161, .3);
			cursor: pointer;

			.yuan1 {
				width: 8vh;
				height: 8vh;
				border-radius: 8vh;
				border: .3vw solid rgba(51, 255, 204, .5);
				text-align: center;

				.text {
					font-size: 1vw;
					color: #6acafb;
					border-bottom: 1px solid #6acafb;
					padding-top: .3vh;
				}

				.number {
					color: #ffffff;
					font-size: 0.9vw;
				}
			}
		}

		.rightmain {
			margin-left: 1vw;
			font-size: 1vw;
			;
			color: #6acafb;
		}

		.jgc {
			margin-top: 8vh;
		}

		.ringhtrow {
			height: 2vh;
			width: 25vw;
			margin-left: 2vw;
			margin-top: 2vh;
			line-height: 2vh;
			border-bottom: 1px solid #09344a;

		}

		.jgx {
			margin-left: 5vw;
		}

		.rigwidth1 {
			width: 8vw;
			text-align: center;
			cursor: pointer;
		}

		.rigwidth2 {
			width: 16vw;
			// text-align: center;
			padding-left: 4vw;
			;
		}

		.lcolor {
			color: #fff;
		}


	}
</style>
